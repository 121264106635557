import { ITextDividerOptions } from '../models/textDivider.model';
import { TEXT_DIVIDER_OPTIONS } from '../constants/textDivider.const';

export function getDividedText(text: string, props?: Partial<ITextDividerOptions>): Array<string> {
  const { parts, isPartsSimilar } = { ...TEXT_DIVIDER_OPTIONS, ...props };
  if (!text) {
    return [];
  }
  if (!parts) {
    return [text];
  }
  const result = [];
  const wordList = text.split(' ');
  const temp = {
    partSize: 0,
    partIndex: 0,
    part: [],
    wordIndex: 0,
    symbolIndex: 0,
  };
  while (temp.wordIndex < wordList.length) {
    const word = wordList[temp.wordIndex];
    const partSize = isPartsSimilar ? (word.length >= parts[0] ? word.length : parts[0]) : parts[temp.partIndex] ?? Infinity;
    const spaceSymbolLength = temp.part.length ? 1 : 0;
    const nextPartSize = temp.partSize + word.length + spaceSymbolLength;
    if (nextPartSize <= partSize) {
      temp.partSize += word.length + spaceSymbolLength;
      temp.part.push(word);
      temp.symbolIndex += word.length + spaceSymbolLength;
      temp.wordIndex++;
    }
    if (nextPartSize >= partSize || temp.symbolIndex >= text.length) {
      temp.partIndex++;
      const part = temp.part.join(' ');
      if (!part) {
        continue;
      }
      result.push(part);
      temp.part = [];
      temp.symbolIndex++;
      temp.partSize = 0;
    }
  }
  return result;
}
